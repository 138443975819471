/* eslint no-unused-vars: 0 */

import PropTypes from "prop-types";
import React from "react";
import { ThemeContext } from "../../layouts";
import { Card, Tag } from "antd";

import { GithubOutlined, GlobalOutlined, LinkOutlined } from "@ant-design/icons";
const { Meta } = Card;

const Projects = (props) => {
  const projects = [
    {
      category: "🧑‍💻 Open Source",
      title: "In-Memory Caching .NET with IMemoryCache (36 ⭐️)",
      description: "Improving performance of monolithic .NET applications with in-memory caching.",
      link: "https://sahansera.dev/in-memory-caching-aspcore-dotnet/",
      repository: "https://github.com/sahansera/InMemoryCacheNetCore",
      liveDemo: "https://www.youtube.com/watch?v=LvjiCEMb88k&ab_channel=SahanSeraDEV",
      technologies: [".NET", "C#", "ASP.NET Core", "IMemoryCache"],
    },
    {
      category: "🧑‍💻 Open Source",
      title: "GitHub API for Java (1K ⭐️) • Contributor",
      description:
        "I contributed to the GitHub API for Java library by adding support for allowing Fork type to be specified when performing a search.",
      link: "https://github.com/hub4j/github-api/releases/tag/github-api-1.301",
      repository: "https://github.com/hub4j/github-api",
      liveDemo: "https://github.com/hub4j/github-api/releases/tag/github-api-1.301",
      technologies: ["Java", "GitHub API", "GitHub"],
    },
    {
      category: "🧑‍💻 Open Source",
      title: "Distributed Caching in ASP.NET Core with Redis (23 ⭐️)",
      description:
        "Improving performance of .NET microservices applications with distributed caching.",
      link: "https://sahansera.dev/distributed-caching-aspnet-core-redis/",
      repository: "https://github.com/sahansera/DistributedCacheAspNetCoreRedis",
      technologies: [".NET", "C#", "ASP.NET Core", "IDistributedCache", "Redis"],
    },
    {
      category: "🧑‍💻 Open Source",
      title: "Understanding WebSockets with .NET (13 ⭐️)",
      description:
        "I presented a reference implementation to understand RFC 6455 WebSockets specification and configure a generic ASP.NET (Core) 5 application to communicate over WebSockets connection with SignalR.",
      link: "https://sahansera.dev/understanding-websockets-with-aspnetcore-5/",
      repository: "https://github.com/sahansera/WebSocketsTutorial",
      technologies: [".NET", "C#", "ASP.NET Core", "WebSockets", "SignalR"],
    },
    {
      category: "🧑‍💻 Open Source",
      title: "gRPC on Go (12 ⭐️)",
      description: "A simple gRPC web service written in Go without using third-party libraries.",
      link: "https://sahansera.dev/building-grpc-server-go/",
      repository: "https://github.com/sahansera/go-grpc",
      technologies: ["Go", "gRPC", "Protocol Buffers"],
    },
    {
      category: "🧑‍💻 Open Source",
      title: "Adding Intelligence to React Apps with Azure Cognitive Services",
      description: "Enhance your React apps with AI-powered features.",
      link: "https://sahansera.dev/adding-intelligence-react-azure-cognitive/",
      repository: "https://github.com/sahansera/rgs-2022-demo",
      liveDemo: "https://www.youtube.com/watch?v=TLr_cxg4EhI&ab_channel=SahanSeraDEV",
      technologies: ["React", "Azure Cognitive Services", "Next.js"],
    },
    {
      category: "⚡️ Side Projects",
      title: "sahansera.dev",
      description:
        "This blog! Over 300K pageviews (as of March 2023), 36 posts with over 14K+ monthly active users. Well received in the .NET & Go Communities.",
      link: "https://sahansera.dev",
      technologies: [
        "React",
        "Gatsby",
        "PWA",
        "ServiceWorker",
        "Ant Design",
        "TypeScript",
        "GraphQL",
      ],
    },
    {
      category: "⚡️ Side Projects",
      title: "Medline Pubmed Data Extractor",
      description:
        "A simple .NET console application that will process a given Medline Pubmed dataset (XML) and generate TSV files with given attributes.",
      repository: "https://github.com/sahansera/medline-pubmed-extractor",
      technologies: [".NET", "C#", "LINQ", "TSV", "XML"],
    },
    {
      category: "🎓 Academic Projects",
      title: "Narrative Visualization with D3.js",
      description:
        "One of my notable achievements was the creation of a captivating narrative visualization, entirely powered by D3.js and vanilla ES6, without relying on any packaging tool. I presented a single-page application architecture, enabling seamless navigation using both keyboard and pointing devices, akin to a presentation tool. To ensure data integrity and efficiency, I used Python's Pandas library for dataset preprocessing.",
      repository: "https://github.com/sahansera/data-visualization-project",
      liveDemo: "https://sahansera.github.io/data-visualization-project/",
      technologies: ["D3.js", "JavaScript", "HTML", "CSS", "Python", "Pandas"],
    },
    {
      category: "🎓 Academic Projects",
      title: "Twitter Sarcastic Tweet Detection using Deep Learning",
      description:
        "During my Master's degree, I had the opportunity to lead a talented team in the highly competitive Text Classification Competition for Sarcasm Detection in Tweets. Our team scored an impressive F1 score of 0.754. In this competition, my primary focus was on the crucial tasks of dataset preprocessing and training a powerful BERT model.",
      technologies: [
        "Python",
        "PyTorch",
        "Deep Learning",
        "Natural Language Processing",
        "BERT",
        "TextBlob",
        "Transformers",
      ],
    },
    {
      category: "🎓 Academic Projects",
      title: "Self-driving car using Raspberry Pi",
      description:
        " I built a self-driving 4WD car leveraging the SunFounder's PiCar kit. Throughout this project, I lead the implementation of the mapping module using the onboard ultrasonic sensor readings. As the project progressed, I further expanded its capabilities by incorporating features like obstacle detection (sign posts, walls, etc.), route mapping, and orientation detection.",
      technologies: [
        "Raspberry Pi",
        "Python",
        "Computer Vision",
        "OpenCV",
        "Ultrasonic Sensor",
        "Matplotlib",
      ],
    },
    {
      category: "🎓 Academic Projects",
      title: "LogXTractor",
      description:
        "My final year undergraduate research project where I researched, designed and implemented a web based IDE for extracting actionable insights from log files. I presented a fully working prototype working with large scale log (>2GB) files and inferencing patterns using ML techniques. This project also won the best academic research project award for 2014 and best academic paper award at the ICIIT 2016 conference",
      liveDemo: "https://www.youtube.com/watch?v=k5UH2g75O6M&ab_channel=SahanSerasinghe",
      technologies: [
        "C",
        "Python",
        "Machine Learning",
        "Log Analysis",
        "Javascript",
        "HTML",
        "CSS",
      ],
    },
    {
      category: "🎓 Academic Projects",
      title: "MobileHub Question Answering System",
      description:
        "Operating as a full stack application, it has a client-side application implemented using vanilla JavaScript, a backend REST API powered by PHP, and a MySQL database for seamless data management. Features implemented to ask and answer questions, engage in voting, utilize tags, close questions, manage users, access an admin panel, and even employ voice search functionality. From inception to completion, I designed and developed every component of this project, leading it to secure the highest marks within my batch.",
      repository: "",
      liveDemo: "https://medium.com/@sahansera/mobilehub-question-answering-system-feaeb75f5085",
      technologies: [
        "PHP",
        "MySQL",
        "HTML",
        "CSS",
        "REST API",
        "JavaScript",
        "jQuery",
        "Bootstrap",
      ],
    },
  ];

  const renderProjectsByCategory = (category, description) => {
    const categoryProjects = projects.filter((project) => project.category === category);

    return (
      <div key={category}>
        <h1 style={{ marginBottom: "1rem", marginTop: "2rem" }}>{category}</h1>
        <p style={{ marginBottom: "2rem" }}>{description}</p>
        {categoryProjects.map((project, index) => (
          <Card
            title={project.title}
            className="project-card"
            size="small"
            actions={[
              project.link && (
                <LinkOutlined key="link" onClick={() => window.open(project.link, "_blank")} />
              ),
              project.repository && (
                <GithubOutlined
                  key="github"
                  onClick={() => window.open(project.repository, "_blank")}
                />
              ),
              project.liveDemo && (
                <GlobalOutlined
                  key="demo"
                  onClick={() => window.open(project.liveDemo, "_blank")}
                />
              ),
            ]}
            key={index}
            style={{ marginBottom: "1rem" }}
          >
            <Meta description={project.description} />
            <div style={{ marginTop: "1rem" }}>
              {project.technologies.map((technology, techIndex) => (
                <Tag color="blue" key={techIndex}>
                  {technology}
                </Tag>
              ))}
            </div>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {(theme) => (
          <>
            <div>
              {renderProjectsByCategory(
                "⚡️ Side Projects",
                "Things that I'm building in my free time."
              )}
              {renderProjectsByCategory(
                "🧑‍💻 Open Source",
                "I've been contributing to open source since 2012! Here are some of my recent notable open source contributions."
              )}
              {renderProjectsByCategory(
                "🎓 Academic Projects",
                "A mix of projects I've built, led, contributed to during my master's and undergrad times."
              )}
            </div>
            <style jsx>{`
              h2 {
                margin-bottom: 0.5em;
              }

              body .project-card {
                box-shadow: 0.8rem 1.4rem 3.8rem rgb(245 245 245 / 6%), 1px 3px 8px rgb(39 44 49 / 3%);
              }
      
              body.dark .project-card {
                box-shadow: 0.8rem 1.4rem 3.8rem rgb(39 44 49 / 6%), 1px 3px 8px rgb(39 44 49 / 3%);
              }
            `}</style>
          </>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

Projects.propTypes = {
  form: PropTypes.object,
};

export default Projects;
